@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.Container {
  @include flex-col;
  gap: 20px;
}

.formsContainer {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.Headline {
  @include flex-col;
  gap:10px;

  * {
    text-align: center;
  }
  label{
    max-width:75vw;
  }
}
.icon{
  position: relative;
  top:2px; 
}
.inputField{
  border-width:2px;
  border-style:solid;
  border-color:black;
  margin: 5px;
  padding: 5px;
}
.btn {
  position: relative;
  display: inline-block;
  width: 370px;
  max-width: 100%;
  height: 35px;

  border-radius: 5px;

  font-size: 18px;
  font-weight: 500;

  outline: none;
  border: none;

  cursor: pointer;
  transition: 0.5s;

  // Temp rules.
  border: 1px solid var(--primary);
  background: var(--primary);
  color: white;
  box-shadow: $shadow-0-0-3;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// OPTIONS
.btn.large {
  width: 420px;
  height: 40px;
  font-size: 20px;
}
.btn.small {
  width: 320px;
  height: 30px;
  font-size: 16px;
}
.btn.x_small {
  width: 160px;
  height: 40px;
  font-size: 16px;
}
.btn.x_x_small {
  width: 100px;
  height: 27px;
  font-size: 12px;
}
.btn.m_small {
  width: 335px;
  height: 35px;
  font-size: 16px;
}
.btn.small.square {
  width: 30px;
}
.btn.square {
  width: 35px;
}

.btn.hover_outlined:hover {
  background: none;
  color: var(--primary);
}
.btn.hover_jump:hover {
  transform: translateY(-2.5px);
}
.btn.hover_dark:hover {
  opacity: 0.9;
}


.SearchBar {
  margin-top: 50px;
  display: grid;
  place-items: center;
}

.CardsWrraper {
  margin-top: 20px;
  padding-inline: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;

  .Card {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 10px;

    h2,
    span {
      display: block;
      text-align: center;
    }
    h2 {
      font-size: 21px;
    }

    .Dates {
      @include flex-space-between;
    }

    & > div {
      display: flex;
      gap: 5px;
      margin-top: 5px;

      button {
        max-width: 200px;
      }
    }

    button:last-of-type {
      display: grid;
      place-items: center;
    }
  }
}