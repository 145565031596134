@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  @include flex;
  text-align: center;

  ul {
    margin-top: 50px;
    list-style: none;

    li {
      @include flex-space-between;
      width: 450px;
      max-width: 90%;
      padding: 8px 15px;
      margin-block: 10px;
      background: white;
      box-shadow: 0 0 2px gray;
      border-radius: 20px;
      cursor: pointer;
      transition: .25s;

      &:hover {
        box-shadow: 0 0 2px var(--primary);

        &, svg {
          color: var(--primary);
        }
      }
    }
  }
}
