@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: calc($header-height + 50px);
  margin-right: 5vw;
  margin-left: 5vw;
  margin-bottom: 5vh;
  @include flex-col;
  gap: 50px;
}

.SearchBar {
  max-width: calc(100% - ($page-padding * 2));
}
.Cards {
  @include flex-wrap;
  align-items: unset;
  gap: 20px;
}
.Card {
  @include flex-space-between;
  flex-direction: column;
  gap: 10px;

  width: 300px;
  padding: 25px;

  border-radius: 20px;
  box-shadow: $shadow-0-0-3;

  cursor: pointer;

  &:hover {
    box-shadow: $primary-shadow-0-0-3;
  }

  h2 {
    text-align: center;
  }
  .Dates {
    @include flex-space-between;
    width: 100%;

    & > * {
      font-size: 14px;
    }

    .date_wrapper{
      @include flex-col;
    }
  }
}
