@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.Container {
  background: var(--primary);
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  @include flex;
}
.Form {
  @include flex-col;

  width: 400px;
  max-width: calc(100% - 50px);
  padding: 25px;

  background: white;

  border-radius: $card-radius;
  box-shadow: $shadow-0-0-3;
  overflow: hidden;
}
.Logo img {
  width: 200px;
}
.Headline {
  @include flex;
  margin-bottom: 20px;
}
.Content {
  /* Step 1 */
  @include flex-col;
  gap: 12.5px;

  max-width: 95%;

  .pickers {
    width: 100%;
    @include flex-space-between;

    div {
      font-size: 14px;
      @include flex;
      gap: 5px;

      cursor: pointer;

      span {
        font-weight: 400;
        color: var(--primary);
      }

      input {
        accent-color: var(--primary);
      }

      label,
      input {
        cursor: pointer;
      }
    }
  }
}
