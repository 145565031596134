// I really do love (to) flex, dont I?

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-col {
  @include flex;
  flex-direction: column;
}
@mixin flex-wrap {
  @include flex;
  flex-wrap: wrap;
}
@mixin flex-align-start {
  @include flex;
  align-items: flex-start;
}
@mixin flex-justify-start {
  @include flex;
  justify-content: flex-start;
}
@mixin flex-space-even {
  @include flex;
  justify-content: space-evenly;
}
@mixin flex-space-between {
  @include flex;
  justify-content: space-between;
}
