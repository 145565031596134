// Temp rules.
// This file should be changed to .css
@import "../../styles/vars.scss";

.Checkbox,
.ThreeWayCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  cursor: pointer;

  font-size: 14px;
}
.Checkbox input,
.ThreeWayCheckbox input {
  all: unset;

  position: relative;
  display: inline-block;
  width: 12.5px;
  height: 12.5px;

  border-radius: 2px;
  border: 1px solid var(--primary);
}
.Checkbox input:checked,
.ThreeWayCheckbox input[status="1"] {
  background: var(--primary);

  &:before {
    content: "\2714";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 75%;
  }
}
.ThreeWayCheckbox input[status="-1"] {
  background-color: white;
  &:before {
    content: "\0058";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary);
    font-size: 75%;
  }
}
