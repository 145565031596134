// Temp rules.
// This file should be changed to .css
@import "../../styles/vars.scss";

.btn {
  position: relative;
  display: inline-block;
  width: 370px;
  max-width: 100%;
  height: 35px;

  border-radius: 5px;

  font-size: 18px;
  font-weight: 500;

  outline: none;
  border: none;

  cursor: pointer;
  transition: 0.5s;

  // Temp rules.
  border: 1px solid var(--primary);
  background: var(--primary);
  color: white;
  box-shadow: $shadow-0-0-3;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// OPTIONS
.btn.large {
  width: 420px;
  height: 40px;
  font-size: 20px;
}
.btn.small {
  width: 320px;
  height: 30px;
  font-size: 16px;
}
.btn.x_small {
  width: 160px;
  height: 40px;
  font-size: 16px;
}
.btn.x_x_small {
  width: 100px;
  height: 27px;
  font-size: 12px;
}
.btn.m_small {
  width: 335px;
  height: 35px;
  font-size: 16px;
}
.btn.small.square {
  width: 30px;
}
.btn.square {
  width: 35px;
}

.btn.hover_outlined:hover {
  background: none;
  color: var(--primary);
}
.btn.hover_jump:hover {
  transform: translateY(-2.5px);
}
.btn.hover_dark:hover {
  opacity: 0.9;
}
