@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.popup{& > div > div {
  width: 450px;
  max-width: 90%;

  & > div {
    @include flex-space-even;
  }
}}
.Container {
  max-width: 90%;
  margin-inline: auto;
  margin-top: 150px;
  @include flex-col;
  gap: 20px;

  .btns {
    gap: 10px;
    &, button {
      @include flex;
    }
  }
}

