@import "../../styles/vars.scss";

.Container {
  position: relative;
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;
}
.LoadingScreenWithHeader {
  height: calc(100vh - 70px);
  margin-top: 70px;
}
.Loader {
  display: flex;
  gap: 5px;

  div {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background: var(--primary);
    animation: Loader 1s linear infinite;

    &:nth-child(even) {
      animation-delay: 0.5s;
    }
  }
}

@keyframes Loader {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  33% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  66% {
    transform: scale(0.8);
    opacity: 1.4;
  }
}
