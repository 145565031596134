@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/vars.scss";

.Container {
  min-height: 100vh;
  @include flex-col;

  h1 {
    font-size: 3rem;
    text-align: center;
  }
  p {
    font-size: 1rem;
    text-align: center;
  }
  svg {
    margin-top: 25px;
    font-size: 10rem;

    path {
      fill: var(--primary);
    }
  }
}
