@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.ProgressBar {
    position: relative;
    width: 100%;
    background: #dedede;
    border-radius: 5px;
    padding-block: 2px;
    @include flex;
    overflow: hidden;
    isolation: isolate;
    color: black;
    font-weight: 600;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--progress);
        min-width: 1%;
        max-width: 99%;
        height: 100%;
        background: var(--primary);
        opacity: .65;
        z-index:-1;
    }
}