@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/vars.scss";

.Container {
  @include flex-col;
  gap: 70px;
  width: 100vw;

  b {
    font-size: 24px;
  }

  embed {
    width: min(80%, 900px);
    aspect-ratio: 1/1;
  }

  .signature {
    @include flex-col;
    gap: 10px;

    &, & * {

      direction: ltr;
    }

    canvas {
      border: 1px solid black;
    }

    div {
      @include flex;
      gap: 5px;
      button {
        @include flex;
      }
    }
  }
}
