@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.Container {
  height: calc(100vh - $header-height);
  @include flex-col;
  gap: 10px;

  margin: 0 25px;

  span,
  h1,
  p {
    width: 700px;
    max-width: 100%;
    text-align: center;
  }
  span {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 2.5rem;
  }
}
