@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.Container {
  padding-block: 50px;

  h1 {
    text-align: center;
  }

  .SearchBar {
    margin-top: 50px;
    display: grid;
    place-items: center;
  }

  .CardsWrraper {
    margin-top: 50px;
    padding-inline: 50px;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;

    .Card {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      padding: 20px;

      h2,
      span {
        display: block;
        text-align: center;
      }
      h2 {
        font-size: 21px;
      }

      .Dates {
        @include flex-space-between;
      }

      & > div {
        display: flex;
        gap: 5px;
        margin-top: 5px;

        button {
          max-width: 200px;
        }
      }

      button:last-of-type {
        display: grid;
        place-items: center;
      }
    }
  }

  .LoadMore {
    margin-top: 50px;
    @include flex;

    button {
      outline: none;
      background: none;
      border: none;

      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
