@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.Notif {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 200;

  height: 50px;
  padding: 5px 30px;

  background: var(--primary);
  color: white;

  border-radius: 10px;

  overflow: hidden;
  @include flex;
  gap: 5px;

  animation: appearance 3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes appearance {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
