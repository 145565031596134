@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  padding: 50px 0;
  @include flex-col;
  gap: 20px;
}
.Headline {
  @include flex;
  width: 100%;
  gap: 5px;
  cursor: pointer;

  svg {
    transition: 0.5s;
  }
  &.open svg {
    transform: rotate(180deg);
  }
}
.hiddenFilters,
.hiddenBtn {
  height: 0;
  overflow: hidden;
}
.Filters {
  width: calc(100% - $page-padding);

  @include flex-col;
  gap: 10px;

  & > div {
    max-width: 1300px;
    @include flex-wrap;
    gap: 20px;
  }
  button {
    @include flex;
  }
}
.TableHeadline {
  width: 100%;
  @include flex-space-between;
  gap: 20px;

  select {
    padding: 5px 10px;
    border-radius: 5px;
    background: #dddddd;

    outline: none;
    cursor: pointer;
  }
}
.Lactors {
  height: 100%;
  @include flex-col;
  align-items: flex-start;
  font-size: 14px;
}
.Center {
  @include flex;
  justify-content: flex-start;
  height: 100%;

  text-decoration: underline;
  color: #333;

  cursor: pointer;
}
.MailBtn{
  margin-top:5px;
}