@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.Container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 120;

  background: rgba(white, 0.5);
  pointer-events: all;

  display: grid;
  place-items: center;
}
.Card {
  position: relative;
  background: white;
  box-shadow: $shadow-0-0-3;
  border-radius: 30px;
  padding: 20px 40px;

  @include flex-col;
  gap: 5px;
}

.Icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @include flex;
  border-radius: 50%;
  padding: 2px;

  &:hover {
    background: #ededed;
  }
}

.Cell {
  width: 100%;

  label {
    display: inline-block;
    width: 110px;
    font-weight: 500;
  }

  a {
    color: #333;
  }
}
