@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  margin-bottom: 50px;
  @include flex-col;

  gap: 20px;

  & > div {
    max-width: 90%;
  }
  button {
    max-width: 80%;
  }
}
.Btns {
  @include flex-col;
  gap: 20px;
}
.Form{
  @include flex-col;
  
  gap:10px;
  width:30%;
}
.ProArts div{
  @include flex-col; 

  align-items: flex-start;
  gap:3px; 
}

.SubTypes{
  gap:4px;
  color:rgb(127, 127, 127);
  margin-right: 20px;
}
h3{
  margin-bottom:10px;
  font-size:15px;
}
