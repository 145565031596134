
.select__wrraper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    select {
        width: 100%;
        padding: 10px 5px;
        border-radius: 5px;
    }

    span {
        font-size: 12px;
        color: red;
    }
}