@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/vars.scss";

.Container {
  @include flex-col;
  gap: 20px;

  & > div,
  & > div > div,
  label {
    @include flex;
  }
  & > div > div > div {
    max-width: 90%;
  }

  .Btn {
    @include flex-col;

    span {
      font-size: 12px;
      color: red;
    }
  }
  .Btn,
  .Btn button {
    max-width: 90%;
  }
}
