@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  padding: 50px 0;
  @include flex-col;
  gap: 20px;
}

.Center {
  @include flex;
  justify-content: flex-start;
  height: 100%;
}

a {
  text-decoration: underline;
  color: #333; 
  cursor: pointer;
}

.pdfBtn{
  margin-top: 15px;
  border:none !important;
  svg{
    width:20px;
    height:20px;
  }
  :hover{
    cursor: pointer;
  }

}