@import "../../../../../../../styles/mixins.scss";
@import "../../../../../../../styles/vars.scss";

.Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  @include flex;

  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;

  button {
    @include flex;
    position: sticky;
    top: 25px;

    appearance: none;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 50%;
    background: var(--primary);
    box-shadow: $primary-shadow-0-0-3;
    opacity: 0.7;
    font-weight: bold;
    cursor: pointer;

    * {
      transition: 0.5s;
    }

    &:hover {
      background: #333;
      svg path {
        stroke: white;
      }
    }
  }

  embed {
    margin-top: 50px;
    width: 90vw;
    height: calc(90vh + 25px);
  }
}
