// Temp rules.
// This file should be changed to .css
@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.Container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: $header-height;

  padding: 0 $page-padding;

  @include flex-space-between;

  background-color: var(--primary);
  z-index: 100;
}

.Logo img {
  position: relative;
  max-height: calc($header-height - 10px);
}

.Links {
  display: flex;
  gap:20px;

  & > ul {
    @include flex;
    gap: 20px;

    list-style: none;
  }
  & > ul li {
    position: relative;
  }
  & > ul li a {
    width: 100%;
    height: 100%;
    @include flex;

    text-decoration: none;

    padding: 2.5px 5px;
    border-radius: 5px;

    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Links.Mobile {
  position: absolute;
  top: $header-height;
  right: 0;

  width: 100%;

  background: var(--primary);

  animation: dropToScreen 0.5s ease-in-out;
  animation-fill-mode: forwards;

  .dropdownList {
    ul li {
      background:var(--primary);
    }
  }
  ul {
    @include flex-col;
    align-items: stretch;
    gap: 0;

    li {
      height: 45px;
      border-top: 1px solid #ddd;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.Hamburger {
  display: none;

  position: relative;
  background: white;

  width: 35px;
  height: 2px;

  cursor: pointer;

  &,
  &::before,
  &::after {
    transition: 0.25s;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -10px;

    width: 30px;
    height: 2px;
    background: white;
  }
  &:after {
    top: 10px;
  }

  &.MenuClose {
    width: 0;

    &:before,
    &:after {
      top: 0;
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}

@media (max-width: 991px) {
  .Hamburger {
    display: block;
  }
  .Links {
    display: none;
  }
  .Links.Mobile {
    display: block;
  }
}
@media (min-width: 991px) {
  .Links > ul li:not(:hover) .dropdownList {
    display: none;
  }
  .dropdownList {
    position: absolute;
    top: 100%;
    width: 200%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 25px;
  
    ul {
      list-style: none;
      background: var(--primary);
      border-radius: 5px;
      padding-block: 5px;

      li {
        padding-block: 2px;
      }
    }
  }
}
