@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.Container {
    width: 90%;
    margin: 50px auto;
  
    @include flex-col;
    gap: 25px;
  
    .screen {
      width: 100%;
    }
  }