@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: calc($header-height + 50px);

  @include flex-col;

  p{
    text-align: center;
    letter-spacing: 1px;
    padding: 0px 25%;

  }
}

.SearchBar {
  max-width: calc(100% - ($page-padding * 2));
}

.Table {
  width: 600px;
  max-width: calc(100% - ($page-padding * 2));
  margin: 50px 0;

  @include flex-col;
  gap: 20px;
}
.Row {
  width: 100%;
  padding: 10px 20px;
  @include flex-space-between;

  border-radius: 10px;
  box-shadow: $shadow-0-0-3;
  cursor: pointer;

  &,
  & * {
    transition: 0.4s;
  }

  &:hover {
    box-shadow: $primary-shadow-0-0-3;

    button {
      background-color: white;
    }
    path {
      fill: var(--primary);
    }
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
  }

  button {
    all: unset;
    border-radius: 50%;
    background-color: var(--primary);

    @include flex;

    svg {
      font-size: 21px;
      path {
        fill: white;
      }
    }
  }
}

.PreviewDiv {
  border: 1px solid var(--primary);
  border-radius: 10px;
  font-size: 24px;
  line-height: 3;
  width: 60%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  white-space: pre-line;

}