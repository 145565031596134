@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/vars.scss";

.Container {
  @include flex-col;
  gap: 20px;

  & > div,
  & > div > div,
  label {
    @include flex-col;
  }
  & > div > div > div {
    max-width: 90%;
  }

  .Btn,
  .Btn button {
    max-width: 90%;
  }
}
.file__names {
  margin-top: 15px;
  @include flex-col;
  gap: 2.5px;

  span {
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }
}
.Btn {
  @include flex-col;

  span {
    font-size: 12px;
    color: red;
  }
}
