@import "../../../../../styles/vars.scss";
@import "../../../../../styles/mixins.scss";

.Container {
  background: #eee;
  width: 900px;
  max-width: 90%;
  margin: 20px auto;

  padding: 20px;
  box-shadow: $shadow-0-0-3;
  border-radius: 15px;

  @include flex-col;
}
.Field {
  margin: 20px;
  @include flex-col;
  gap: 5px;

  & > * {
    max-width: 80% !important;
  }
  label {
    font-size: 12px;
  }
  input[type="range"] {
    accent-color: var(--primary);
  }
  small {
    color: red;
  }
}
.Btn {
  margin-top: 15px;
  @include flex-col;
  gap: 5px;
  & > * {
    max-width: 70% !important;
  }
}
