// $primary: brown;

/* Shadows */
$shadow-0-0-3: 0 0px 3px 0 rgba(black, 0.2), 0 1px 2px 0 rgba(black, 0.06);
$primary-shadow-0-0-3: 0 0 3px rgba(black, 0.5);

/* Radiusim */
$card-radius: 30px;

// sizes
$header-height: 70px;
$page-padding: 7.5%;
