@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.Container {
  height: calc(100vh - $header-height);
}
.Top {
  height: 100px;
  border: 1px solid #ededed;

  @include flex;
  gap: 2.5px;

  p {
    @include flex-wrap;
    gap: 5px;
    max-width: 75%;
  }
  b {
    position: relative;
    cursor: pointer;

    &:hover:before {
      content: "-";
      @include flex;
      position: absolute;
      height: 20px;
      width: 20px;
      top: -20px;
      left: 50%;
      right: calc(50% - 10px);
      border-radius: 50%;
      background: white;
      color: var(--primary);
      box-shadow: $primary-shadow-0-0-3;

      animation: fadeIn 0.25s ease;
    }
    @keyframes fadeIn {
      from {
        top: 0;
        opacity: 0;
      }
      to {
        top: -20px;
        opacity: 1;
      }
    }
  }
  button {
    margin-right: 5px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: none;
    background: var(--primary);
    @include flex;
    cursor: pointer;
    box-shadow: $shadow-0-0-3;

    svg {
      margin-right: 1px;
      transform: rotate(180deg);
      fill: white;
    }
  }
}
.Bottom {
  @include flex-wrap;
  align-items: flex-start;

  & > div {
    height: calc(100vh - $header-height - 100px);
    min-width: 300px;
    flex: 1;
    border: 1px solid #ededed;
    border-bottom: none;

    overflow: auto;

    padding: 20px 0;
    @include flex-col;
    justify-content: flex-start;

    .SearchBar {
      @include flex-wrap;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      button {
        margin-top: 3px;
        @include flex;
      }
    }
  }
}

.Right .Rows {
  margin: 30px 0;
  @include flex-wrap;

  gap: 15px;

  width: 100%;
}

.Row {
  width: 45%;
  min-width: 350px;
  padding: 10px 20px;

  @include flex;
  gap: 20px;
  justify-content: space-between;

  border-radius: $card-radius;
  box-shadow: $shadow-0-0-3;
  cursor: pointer;

  .ProgressBarDiv {
    flex: 1;

    div {
      height: 15px;
      font-size: 12px;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
  }
  div {
    @include flex;
    svg {
      transform: rotate(180deg);
      font-size: 20px;
      fill: var(--primary);

      transition: all 0.3s ease;
    }
  }
  &:not(.Active):hover {
    box-shadow: $primary-shadow-0-0-3;
    svg {
      transform: rotate(180deg) translateY(2.5px);
    }
  }
}
.Left {
  .Cards {
    margin: 30px;
    @include flex-wrap;
    gap: 20px;
  }
  .Card {
    @include flex-col;
    gap: 10px;
    min-height: 120px;
    padding-block: 10px;
    width: 350px;
    box-shadow: $shadow-0-0-3;
    border-radius: $card-radius;
    cursor: pointer;

    &:hover {
      box-shadow: $primary-shadow-0-0-3;
    }

    .Tags {
      @include flex-wrap;

      padding: 0 20px;
      gap: 10px;

      .Tag {
        @include flex;
        gap: 2.5px;
        background: #ededed;
        padding: 0 5px;
        border-radius: 5px;

        &:nth-child(2) {
          background: var(--primary);
          opacity: 0.5;
          color: white;
        }
      }
    }
  }
}

.Active {
  opacity: 0.5;
  cursor: no-drop !important;
}
.LoadMore {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  font-size: medium;
}
