@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  @include flex-col;
  gap: 20px;

  div {
    max-width: 100%;
    gap: 10px;
  }
  button {
    max-width: 85%;
  }
}
.label {
  margin-bottom: 5px;
  font-size: 18px;
  text-align: start;
}

.sub_types_selector {
  label{

    justify-content: flex-start;
  }
  p {
    margin-bottom: 10px;
  }
}