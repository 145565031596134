@import "../../../../styles/mixins.scss";
@import "../../../../styles/vars.scss";

.Container {
  margin: 120px 0;
  @include flex-col;
  gap: 20px;
}
.Headline {
  @include flex-col;
  gap:10px;

  * {
    text-align: center;
  }
  label{
    max-width:75vw;
  }
}
.icon{
  position: relative;
  top:2px; 
}
