@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.Container {
  min-height: 100vh;
  padding:10% 20%;

  @include flex-wrap;
  gap: 40px;

  div {
    width: 400px;
    max-width: 95%;
    height: 200px;
    box-shadow: $shadow-0-0-3;
    border-radius: 30px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.5s;

    &:hover {
      box-shadow: 0 0 5px var(--color);
    }

    a {
      @include flex;
      height: 100%;
      width: 100%;
      font-size: 2rem;
      font-weight: 500;
      text-decoration: none;
      color: #333;
      transition: 0.5s;

      &:hover {
        color: var(--color);
      }
    }
  }
}
