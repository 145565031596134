@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.container {
  margin-top: 120px;

  @include flex-col;
  gap: 15px;
}
.SearchBar {
  @include flex;
  gap: 5px;

  button {
    margin-top: 5px;
    @include flex;
  }
}
.Cards {
  max-width: 80%;
  margin: 50px auto;
  @include flex-wrap;
  align-items: stretch;
  gap: 20px;
}

.Card {
  @include flex-col;
  gap: 10px;
  box-shadow: $shadow-0-0-3;
  padding: 30px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  }

  .Info {
    width: 80%;
    text-align: center;

    h2 {
      margin-bottom: 15px;
    }
    .Dates {
      @include flex-space-between;
    }
  }
  .Btns {
    @include flex-wrap;
    gap: 10px;

    button {
      @include flex;
      max-width: 80%;

      &:nth-child(1),
      &:nth-child(2) {
        color: var(--primary);
        background: white;
        border: none;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

        &:hover {
          box-shadow: 0 0 5px var(--primary);
        }
      }
    }
  }
}
.LoadMore {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  font-size: medium;
}
