@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.Container {
  width: 90%;
  margin: 50px auto;

  @include flex-col;
  gap: 25px;

  .screen {
    width: 100%;
  }
}
.File {
  @include flex-col;
  width: 100%;

  embed {
    width: 80%;
    height: 70vh;
  }
}
.Changes {
  max-width: 90%;
  margin: 0 auto;

  @include flex-wrap;
  gap: 10px;

  margin-top: 20px;

  margin-bottom: 100px;
  &:last-child {
    margin-bottom: 33px;
  }

  & > div:last-child {
    @include flex;
    gap: 5px;

    button {
      margin-top: 5px;
      @include flex;
    }
  }
}

.Add button {
  @include flex;
  gap: 5px;

  svg {
    transition: 0.2s;
  }
  &:hover svg {
    transform: translateY(-1.5px);
  }
}
