/* Layout stuff */
.Table {
  max-width: 90vw;
}
.SearchBar,
.Rows,
.TableFooter {
  overflow-x: scroll;
  white-space: nowrap;
}
.Rows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Row {
  display: flex;
}
/* end Layout stuff */

.Table {
  border-radius: 10px;
  border: 1px solid #ddd;
}
.SearchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SearchBar > * {
  padding: 10px;
}
.Row {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.Row:last-child:not(.Thead) {
  border-bottom: unset;
}
.Row:not(.Thead):nth-child(even) {
  background: #eee;
}
.RegularCell,
.Cell > *[center="true"] {
  padding: 15px 0px;
  font-size: 15px;
}
.TableFooter {
  padding: 10px;
  border-top: 1px solid #ededed;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TableFooter * {
  direction: ltr;
}

.TableFooter select {
  width: 100px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
}

.CenterInTable {
  width: 100%;
  padding: 75px 0;

  display: grid;
  place-items: center;
}

.Thead > div {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
}
.Arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.5px;
}
.Arrows div {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #666;
}
.Arrows div:last-child {
  transform: rotate(180deg);
}
.Arrows div.Active {
  border-bottom-color: black;
}
