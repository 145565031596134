@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  @include flex-col;
}
.Search {
  @include flex-wrap;
  gap: 20px;

  button {
    margin-top: 17.5px;
    @include flex;
  }
}
.Cards {
  margin: 50px 0;

  @include flex-wrap;
  align-items: stretch;
  gap: 20px;

  max-width: 90%;
}

.Card {
  box-shadow: $shadow-0-0-3;
  padding: 20px 40px;
  border-radius: 30px;
  max-width: 90%;

  width: 450px;
  min-height: 175px;

  @include flex-col;
  gap: 10px;

  cursor: pointer;
  transition: 0.25s;

  &:hover {
    box-shadow: $primary-shadow-0-0-3;
  }

  .Name {
    @include flex;
  }
  .Info {
    @include flex-wrap;
    gap: 10px;

    span {
      background: #ededed;
      padding: 2px 5px;
      border-radius: 5px;

      &:first-child {
        background: var(--primary);
        color: white;
        opacity: 0.5;
      }
    }
  }
  .Btns {
    @include flex-wrap;
    gap: 10px;

    button {
      max-width: 60%;
      @include flex;
    }
  }
}

.LoadMore {
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  padding-bottom: 1rem;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  font-size: medium;
}
