@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.Container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 120;

  background: rgba(white, 0.5);
  pointer-events: all;

  display: grid;
  place-items: center;
}
.Dialog {
  @include flex-col;
  height: 100px;
  width: 300px;
  background: var(--primary);
  opacity: 1;
  border-radius: 20px;
  color: white;
  overflow: hidden;
  box-shadow: $shadow-0-0-3;

  & > div:first-child {
    flex: 6;
    @include flex;
    text-align: center;
    padding: 0 30px;
  }
  & > div:last-child {
    flex: 4;

    @include flex;
    align-items: flex-start;
    width: 100%;

    & > button {
      flex: 1;
      height: 100%;

      font-weight: 500;
      border: none;
      outline: none;
      cursor: pointer;
      transition: 0.5s;

      &:first-child {
        background: rgba(white, 0.5);
      }
      &:hover {
        background: var(--primary);
        color: white;
      }
    }
  }
}
