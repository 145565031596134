.Container {
  position: fixed;
  top: 100px;
  left: 0;
  padding: 20px 30px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  border-radius: 0 25px 25px 0;
  background: white;

  transition: left 0.25s ease-in-out;

  &.hidden {
    left: -200px;
  }

  select {
    padding: 5px 25px;
    border-radius: 10px;
    background: var(--primary);
  }

  & > div {
    color: white !important;
    font-size: 0em;
  }

  [style="white-space:nowrap"] {
    display: none !important;
  }
  img {
    display: none;
  }
}
