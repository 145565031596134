@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  margin-bottom: 50px;
  @include flex-col;
  gap: 20px;

  .Inputs {
    @include flex-col;
    gap: 10px;
  }
}

.Lactors {
  width: 100%;
  // max-width: 90%;
  margin: 0 auto;

  @include flex-col;
  gap: 7.5px;

  & > div {
    width: 100%;
  }
  .preview {
    width: 350px;
    margin: 0 auto;
    max-width: 90%;
    @include flex;
    gap: 5px;
  }
  .Lactor {
    padding: 5px 10px;
    background: #ddd;
    border-radius: 5px;
    width: 100%;
    @include flex-space-between;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  button {
    @include flex;
  }
}

.Btns{
  @include flex;
   gap:4px;
}
