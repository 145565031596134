@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  margin-bottom: 50px;
  @include flex-col;
  gap: 20px;

  h2,
  h3 {
    margin: 10px 30px;
    text-align: center;
  }
}

.Fields {
  width: 100%;
  @include flex-col;
  gap: 20px;

  & > div {
    width: 100%;
    @include flex-col;
  }
}

.file {
  @include flex;
  cursor: pointer;

  &.show svg {
    transform: rotate(0deg);
  }
  svg {
    transform: rotate(180deg);
  }
  &:hover {
    &,
    * {
      fill: var(--primary);
    }
  }
}

.Grade {
  margin: 15px 0;
  @include flex-col;
  gap: 10px;
  max-width: 90%;

  label {
    @include flex-col;

    input {
      accent-color: var(--primary);
    }
  }
  span {
    text-align: center;
  }
}

.Embed {
  margin-top: 25px;
  @include flex;
  width: 100%;

  a {
    text-align: center;
  }
  .PDF {
    // width: 450px;
    width: 90% !important;
    height: 595px; // height of A4 page in pixels (I think)
  }
  iframe {
    width: 450px;
    max-width: 95%;
  }
}

.BlockedIframe {
  @include flex-col;
  gap: 10px;
  box-shadow: $shadow-0-0-3;
  padding: 20px 40px;
  border-radius: 30px;
  max-width: 90%;

  span {
    background: #ddd;
    border-radius: 4px;
    height: 25px;
    box-shadow: $shadow-0-0-3;
    overflow: hidden;
    padding: 0 5px;
    @include flex;
  }
}
