@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;

  padding-block: 100px;
  height: 100vh;

  background: rgba(255, 255, 255, 0.5);

  z-index: 120;
  
  display: grid;
  place-items: center;

  overflow-y:scroll;

  button {
    max-width: 90%;
    @include flex;
  }
}

.Card {
  position: relative;
  background: white;
  box-shadow: $shadow-0-0-3;
  padding: 20px 40px;
  width: 500px;
  max-width: calc(100% - 80px - 5%);
  border-radius: 30px;

  @include flex-col;
  gap: 20px;
}
.Icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.options {
  @include flex-col;
  gap: 10px;
  button {
    max-width: 80%;
    @include flex;
    gap: 4px;
  }
}
.columns {
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  gap: 2.5px;
  width: 100%;
  padding: 10px;

  & > * {
    @include flex;
    justify-content: flex-start;
  }

}
