@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.Container {
  margin-top: 120px;
  @include flex-wrap;
  gap: 20px;
}
.Card {
  box-shadow: $shadow-0-0-3;
  padding: 20px 40px;
  border-radius: 30px;

  @include flex-col;
  gap: 15px;

  cursor: pointer;
  transition: 0.25s;

  &:first-child {
    color: white;
    background-color: var(--primary);
  }
  &.Active {
    box-shadow: $primary-shadow-0-0-3;
  }
  &:hover {
    box-shadow: $primary-shadow-0-0-3;
  }

  h2 {
    font-size: 24px;
  }

  div {
    width: 100%;
    @include flex-space-between;
    gap: 50px;
  }
}
