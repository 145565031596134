@import "../../../../../styles/vars.scss";
@import "../../../../../styles/mixins.scss";

.Container {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;

  background: rgba(255, 255, 255, 0.8);
  @include flex;
}
.Card {
  position: relative;
  width: 800px;
  padding: 30px 0;

  max-width: 90%;
  box-shadow: $shadow-0-0-3;
  border-radius: 30px;
  background: white;

  @include flex-col;
  gap: 15px;
}
.CrrLactors {
  @include flex;
  gap: 10px;

  .Lactor {
    & > *:first-child {
      margin-left: 10px;
    }

    &:before {
      content: "-";
      @include flex;
      position: absolute;
      height: 20px;
      width: 20px;
      top: 0;
      left: 50%;
      right: calc(50% - 10px);
      border-radius: 50%;
      background: white;
      opacity: 0;
      color: var(--primary);
      box-shadow: $primary-shadow-0-0-3;
    }
    &:hover:before {
      animation: fadeIn 0.25s ease;
      animation-fill-mode: forwards;
    }
    @keyframes fadeIn {
      from {
        top: 0;
        opacity: 0;
      }
      to {
        top: -10px;
        opacity: 1;
      }
    }
  }
}
.Lactor {
  position: relative;
  background: #eee;
  padding: 2px 4px;
  border-radius: 0.2rem;
  box-shadow: $shadow-0-0-3;
  @include flex-space-between;
  cursor: pointer;

  &:hover i svg {
    transform: translateY(-2px) rotate(180deg);
  }
  i {
    @include flex;
  }
  svg {
    transform: rotate(180deg);
    transition: 0.3s ease;

    path {
      fill: var(--primary);
    }
  }
}
.Close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.AllLactors_Container {
  margin-top: 20px;
  @include flex-col;

  .AllLactors {
    margin: 15px 0;
    @include flex-wrap;
    gap: 10px;
  }
}
